import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";

import { FireWebToCase } from "../../../services/SalesForceService";
import Lockr from "lockr";

import check_icon from "../../../assets/icons/check_blue.svg";
import trash_icon from "../../../assets/icons/trash_icon.svg";
import cart_icon from "../../../assets/icons/cart_icon.svg";
import { GetPlansService } from "../../../services/SapService";
import { formatToBRL } from "brazilian-values";
import SupportService from "../../../services/SupportService";
import { PlansChooser } from "../../PlansChooser";

const Form = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [vehicle, setVehicle] = useState({
    dtAllowed: "",
    hrAllowed: "",
    numberPlate: "",
    vAllowed: true,
    vDescMnf: "",
    vDescModel: "",
    vMnf: "",
    vModel: "",
    vYear: "",
  });
  const [isSelectedOption, setIsSelectedOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [unitPrice, setUnitPrice] = useState("");
  const [initialUnitPrice, setInitialUnitPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [currentPlan, setCurrentPlan] = useState("");
  const [planChanges, setPlanChanges] = useState({});
  const [items, setItems] = useState(false);
  const [itensPlano, setItensPlano] = useState([
    {
      codigoItem: "",
      descricao: "",
      preco: 0,
    },
  ]);
  const [additionalItem, setAdditionalItem] = useState([
    {
      itemCode: "",
      itemName: "",
      unitPrice: 0,
    },
  ]);

  const [myReboqKM, setMyReboqKm] = useState({
    price: 0,
    itemName: "",
  });

  const [reboqKM, setReboqKm] = useState([
    {
      preco: 0,
      descricao: "",
    },
  ]);

  const [myExtras, setMyExtras] = useState([
    {
      itemCode: "",
      itemName: "",
      unitPrice: 0,
    },
  ]);

  const [extras, setExtras] = useState([
    {
      itemCode: "",
      itemName: "",
      unitPrice: 0,
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOptionChange = (event) => {
    const selectedDesc = event.target.value;
    const selectedOption = reboqKM.find(
      (option) => option.descricao === selectedDesc,
    );

    setIsSelectedOption(true);
    setSelectedOption(selectedOption || { preco: 0, descricao: "" });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    additionalItemsInitial();
  }, [itensPlano, additionalItem]);

  function additionalItemsInitial() {
    const additionalCodes = additionalItem.map((item) => item.itemCode);

    let myExtras;
    myExtras = additionalItem.map((item) => ({
      itemCode: item.itemCode,
      unitPrice: item.unitPrice,
      itemName: item.itemName,
    }));

    const extras = itensPlano
      .filter((item) => !additionalCodes.includes(item.codigoItem))
      .map((item) => ({
        itemCode: item.codigoItem,
        unitPrice: item.preco,
        itemName: item.descricao,
      }));

    setMyExtras(myExtras);
    setExtras(extras);
  }

  const handleAdd = (item) => {
    setMyExtras([...myExtras, item]);
    setExtras(extras.filter((extra) => extra.itemCode !== item.itemCode));
  };

  const handleRemove = (item) => {
    setExtras([...extras, item]);
    setMyExtras(myExtras.filter((extra) => extra.itemCode !== item.itemCode));
  };

  const handleAddKm = () => {
    if (selectedOption) {
      setMyReboqKm({
        price: selectedOption.preco,
        itemName: selectedOption.descricao,
      });
      setIsSelectedOption(true);
      closeModal();
    }
  };

  const handleRemoveKm = () => {
    setMyReboqKm({
      price: 0,
      itemName: "",
    });
    setSelectedOption(null);
    setIsSelectedOption(false);
  };

  const handlePlanChanges = (changes) => {
    setPlanChanges(changes);
    setTotalPrice(unitPrice + changes.amountIncluded + changes.amountExcluded);
  };

  useEffect(() => {
    async function handleClientData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        setIformationClientData(clientData);
      } else {
        clientData = Lockr.get("ClientData");
        setIformationClientData(clientData);
      }
    }

    async function loadData() {
      props.values.setLoading(true);
      handleClientData();

      const contractId =
        Lockr.get("ClientData").customerSelectedCar.contractInfo.nCtrId;
      const result = await SupportService.getPlans(
        contractId.split("-")[0],
        contractId,
      );
      setItems(result);
      props.values.setLoading(false);
    }

    loadData();
  }, [props.nCtrId]);

  const phone = props.values.contactPhone;
  const onSubmit = async (data) => {
    try {
      const names = myExtras.map((extra) => {
        if (extra.id === 1 || extra.id === 2) {
          return extra.name;
        } else if (extra.id === 3) {
          return `${extra.name} ${extra.planKM}`;
        }
      });

      const reason = "Alteração de plano";
      const subject = "Alteração de plano";
      const description = `
          Telefone para contato: ${phone}
          Numero da placa: ${vehicle.numberPlate}
          Modelo do veiculo: ${vehicle.vModel}
          ---
          Valor antigo: ${formatToBRL(unitPrice)}
          Valor novo: ${formatToBRL(totalPrice)}
          ---
          Itens adicionais:
            ${myExtras.map((item) => `(${item.itemCode}): ${item.itemName} - ${formatToBRL(item.unitPrice)}`).join(", ")}
          ---
          ${
            myReboqKM.itemName.length > 0
              ? `Km Adicional:

        (${myReboqKM.baseItem}) ${myReboqKM.itemCode} ${myReboqKM.itemName} ${formatToBRL(myReboqKM.price)} (quantidade: ${myReboqKM.quantity})
          ---`
              : ``
          }
          Modificações no plano:
          (Itens adicionados)
          ${planChanges.includedInPlan.map((i) => `(${i.service_code}) ${i.service_name} - ${formatToBRL(i.value)}`).join(", ")}

          (Itens removidos)
          ${planChanges.excludedFromPlan
            .map((i) => {
              if (i.$isReboque) {
                return `(${i.service_code}) ${i.service_name} - ${formatToBRL(i.$selectedKmFromContract.price)}`;
              }

              return `(${i.service_code}) ${i.service_name} - ${formatToBRL(i.value)}`;
            })
            .join(", ")}
        `;

      const formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      props.values.setLoading(true);

      await FireWebToCase(JSON.parse(formDataJson));

      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  const setIformationClientData = async (clientData) => {
    const customerSelectedCar = clientData.customerSelectedCar;
    const contractInfo = customerSelectedCar.contractInfo;
    setVehicle(contractInfo.vehicle);
    setClientId(contractInfo.bpName);
    setContractId(contractInfo.nCtrId);
    props.handleChangeNumberPlate(contractInfo.vehicle.numberPlate);
    props.handleChangeModel(contractInfo.vehicle.vDescModel);

    setCurrentPlan(contractInfo.idPlan);
    const filteredAdditionalItems = contractInfo.salesContractItems.filter(
      (obj) =>
        obj.unitPrice > 0 &&
        obj.itemCode !== "SRV_ADESAO" &&
        obj.itemName !== "Assinatura Mensal Loovi",
    );

    const filterMyAss24 = contractInfo.salesContractItems.filter(
      (obj) => obj.itemCode === "SRV_ASS24",
    );

    if (filterMyAss24) {
      if (!filterMyAss24?.[0]?.subItems) {
        return;
      }

      const filterMyReboqKm = filterMyAss24[0].subItems.filter(
        (obj) => obj.itemCode === "SRV_REBOQ",
      );

      setMyReboqKm(filterMyReboqKm[0].subItemsCategory[0]);
    }

    setAdditionalItem(filteredAdditionalItems);

    const initialValue = contractInfo.salesContractItems.reduce(
      (total, item) => {
        if (item.itemCode !== "SRV_ADESAO") {
          return total + item.unitPrice;
        }
        return total;
      },
      0,
    );

    setInitialUnitPrice(initialValue);
    setUnitPrice(initialValue);
    setTotalPrice(initialValue);

    const parts = contractInfo.idPlan.split("_");
    const lastPart = parts[parts.length - 1];
    const response = await GetPlansService(lastPart);

    const filteredItems = response.filter(
      (obj) => obj.idPlano === contractInfo.idPlan,
    );

    if (filteredItems.length > 0) {
      const filteredItemsByPrice = filteredItems[0].itensPlano.filter(
        (item) => item.preco > 0,
      );
      setItensPlano(filteredItemsByPrice);

      const srvAss24 = filteredItems[0].itensPlano.find(
        (subItem) => subItem.codigoItem === "SRV_ASS24",
      );

      if (srvAss24) {
        const srvReboq = srvAss24.formularioSubItens.subItemPlano.find(
          (subItem) => subItem.codigoItem === "SRV_REBOQ",
        );

        if (srvReboq) {
          setReboqKm(srvReboq.formularioCategorias.categoriaSubItem);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="selected-car">
            <p>Veículo Assegurado</p>
            <div className="car-info">
              <p>Modelo:</p>
              <p className="car-info-value">{vehicle.vModel}</p>
            </div>
            <div className="car-info">
              <p>Placa:</p>
              <p className="car-info-value">{vehicle.numberPlate}</p>
            </div>
          </div>
          <div className="basic-items">
            <p className="title">Seguro Loovi</p>
            {items &&
              items.required
                .filter((i) => {
                  if (
                    i.service_name === "Furto e Roubo" &&
                    items.required.findIndex(
                      (ir) => ir.service_name === "Perda Total (Furto e Roubo)",
                    ) > -1
                  ) {
                    return false;
                  }

                  return true;
                })
                .map((item) => (
                  <>
                    <div className="basic-item">
                      <img src={check_icon} />
                      <p>{item.service_name}</p>
                    </div>
                    <div className="line"></div>
                  </>
                ))}
          </div>
          <p className="title">Planos adicionais</p>
          {items && (
            <PlansChooser
              added={items.added}
              notAdded={items.notAdded}
              onChange={handlePlanChanges}
            />
          )}
          <div className="monthly-payment">
            <p>Valor do plano mensal:</p>
            <div className="section-value">
              <p className="value-payment">{formatToBRL(totalPrice)}</p>
            </div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              <p>Estou de acordo com a alteração.</p>
            </label>
          </div>
        </>
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-imgs">
              <h3 className="loovi-header-title loovi-color-grey-2">
                Alterar Quilometragem na assistência
              </h3>
              <h4>
                Defina a quantidade de quilometragem você deseja para sua
                assistência.
              </h4>
              <div className="infos-text">
                {reboqKM.map((option) => (
                  <div key={option.descricao} className="option-selected">
                    <div className="info-text">
                      <input
                        type="radio"
                        name="option-km"
                        value={option.descricao}
                        checked={
                          selectedOption &&
                          selectedOption.descricao === option.descricao
                        }
                        onChange={handleOptionChange}
                      />
                      <p>{option.descricao}</p>
                    </div>
                    <p>+{formatToBRL(option.preco)}</p>
                  </div>
                ))}
              </div>
              {!isSelectedOption ? (
                <button
                  type="button"
                  className="btn-next"
                  disabled={!selectedOption}
                >
                  Adicionar
                </button>
              ) : (
                <button type="button" className="btn-next" onClick={closeModal}>
                  Adicionar
                </button>
              )}
            </div>
          </div>
        )}
        <input
          type="submit"
          value="Confirmar alteração"
          disabled={!isCheckboxChecked}
        />
      </form>
    </React.Fragment>
  );
};

export class FormChangePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      vDescModel: "",
      numberPlate: "",
      unitPrice: 0,
      additional: {
        possuiCoberturaColisao: false,
        possuiCoberturaVidros: false,
      },
      additionalKm: "",
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChangeModel = (value) => {
    this.setState({ vDescModel: value });
  };

  handleChangeNumberPlate = (value) => {
    this.setState({ numberPlate: value });
  };

  handleChangeUnitPrice = (value) => {
    this.setState({ unitPrice: value });
  };

  handleChangeAdditional = (event) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      additional: {
        ...prevState.additional,
        [name]: checked,
      },
    }));
  };

  handleChangeAdditionalKm = (value) => {
    this.setState({ additionalKm: value });
  };

  render() {
    const {
      step,
      numberPlate,
      vDescModel,
      unitPrice,
      possuiCoberturaColisao,
      possuiCoberturaVidros,
      additionalKm,
    } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      numberPlate,
      vDescModel,
      unitPrice,
      possuiCoberturaColisao,
      possuiCoberturaVidros,
      additionalKm,
      contactPhone,
      setLoading,
    };

    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleChangeNumberPlate={this.handleChangeNumberPlate}
          handleChangeModel={this.handleChangeModel}
          handleChangeUnitPrice={this.handleChangeUnitPrice}
          handleChangeAdditional={this.handleChangeAdditional}
          handleChangeAdditionalKm={this.handleChangeAdditionalKm}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  .monthly-payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.5rem;
  }

  .monthly-payment p {
    color: #a1a4ab;
    font-size: 14px;
    font-weight: 400;
  }

  .section-value {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .value-payment {
    color: #5a78ff !important;
    font-size: 36px !important;
    font-weight: 800 !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
  }

  .checkbox p {
    margin-left: 0.5rem;
  }

  .current-payment {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #a1a4ab;
    font-size: 14px;
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .current-payment p {
    margin-left: 0.5rem;
    color: #a1a4ab;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
  }

  .basic-items {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .basic-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
  }

  .basic-item P {
    color: #a1a4ab !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-left: 0.5rem;
    margin-top: 0rem !important;
  }

  .basic-item img {
    width: 20px;
    height: 20px;
  }

  .title,
  .selected-car p {
    font-weight: 700;
    font-size: 15px;
    color: #515151;
    margin-top: 1.5rem;
  }

  .car-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }

  .car-info p, .car-info-value {
    color: #a1a4ab !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-top: 0rem !important;
  }

  .car-info-value {
    flex: 1;
    text-align: right;
    text-transform: capitalize;
  }

  .additional-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 2px solid #f2f3f8;
    border-radius: 12px;
    padding: 16px 12px;
    height: 66px;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  .additional-item p {
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0rem;
    width: 50%;
  }

  .additional-item-large {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 2px solid #f2f3f8;
    border-radius: 12px;
    padding: 16px 12px;
    height: 66px;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  .additional-item-large p {
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0rem;
    width: 60%;
  }

  .item-value, .item-value-large {
    display: flex;
    flex-direction: row;
  }

  .item-value p {
    color: #A1A4AB !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-top: 0rem !important;
    text-align: right;
    margin-right: 1rem;
  }

  .item-value-large p {
    color: #A1A4AB !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-top: 0rem !important;
    text-align: right;
  }

  .btn-item-addtional {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btn-item-addtional p {
    text-align: center !important
    font-size: 10px !important;
    margin: 0 !important;
    width: 100%;
  }

  .btn-item-addtional img {
    width: 14px;
    height: 14px;
    margint-bottom: 0.2rem
  }

  .remove p {
    color: #FF5A5A !important
  }

  .add p {
    color: #18A16D !important
  }

  .available-item-text, .acquired-item-text {
    font-size: 12px
    font-weight: 700;
    color: #A1A4AB;
    margin-top: 1rem
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .modal-imgs {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: auto;
    max-width: 500px;
  }

  .modal-imgs h3 {
    color: #A1A4AB
  }

  .modal-imgs h4 {
    color: #5A78FF;
    font-size: 20px;
    font-weight: 700;
  }

  .infos-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .btn-next {
    background: #5A78FF;
    color: #FFFFFF;
    padding: 4px 0px;
    border: none;
    border-radius: 4px;
    width: 220px;
    margin-top: 4rem;
  }

  .btn-next:disabled {
    background: #D5D5D5;
    color: #515151;
  }

  @media screen and (max-width: 768px) {
    .btn-next {
        width: 90% !important;
        height: 48px;
        position: absolute;
        bottom: 1rem;
        margin-top: 4rem;
      }

    .modal-imgs {
      background-color: #fff;
      padding: 20px;
      border-radius: 0;
      box-shadow: 0px;
      width: 100%;
      height: 100vh;
    }

    .modal-imgs h4 {
      text-align: left;
    }

    .modal-imgs h3 {
      margin-top: 5rem;
    }
  }

  @media screen and (min-width: 768px) {
    .modal-imgs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .modal-imgs h4 {
      text-align: center;
    }
  }

  .info-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .option-selected {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5rem;
    justify-content: space-between;
  }

  .option-selected p {
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  form input[type="radio"] {
    width: 32px;
    height: 24px;
  }

  .line {
    background: #F2F3F8;
    height: 2px;
    width: 100%;
    margin-top: 0.3rem
  }
`;
