import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import moment from "moment";

import {
  FireWebToCase,
  GetFireWebToCase,
} from "../../../services/SalesForceService";
import Lockr from "lockr";
import arrow_down from "../../../assets/icons/arrow_down.svg";
import alert_icon_yellow from "../../../assets/icons/alert_icon_yellow.svg";
import {
  CalcProRataService,
  GerarProRataService,
} from "../../../services/SapService";
import { formatToBRL } from "brazilian-values";
import { delay } from "../../../helpers/timeout";

const Form = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [unitPrice, setUnitPrice] = useState("");
  const [newUnitPrice, setNewUnitPrice] = useState(0);
  const [promotionValue, setPromotionValue] = useState(0);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [dtPmnt, setDtPmnt] = useState("");
  const [proRata, setProRata] = useState({
    contrato: "",
    valorMensalidade: 0,
    diasProporcionais: 0,
    valorVoucher: 0,
    valorAcrescimo: 0,
  });

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  let currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        setIformationClientData(clientData);
      } else {
        clientData = Lockr.get("ClientData");
        setIformationClientData(clientData);
      }
    }

    loadData();
  }, [props.nCtrId]);

  const setIformationClientData = async (clientData) => {
    const customerSelectedCar = clientData.customerSelectedCar;
    const contractInfo = customerSelectedCar.contractInfo;
    const initialValue = contractInfo.salesContractItems.reduce(
      (total, item) => {
        if (item.itemCode !== "SRV_ADESAO") {
          return total + item.unitPrice;
        }
        return total;
      },
      0,
    );
    setUnitPrice(initialValue);
    setClientId(contractInfo.bpName);
    setContractId(contractInfo.nCtrId);
    const [datePart] = contractInfo.dtPmnt.split("T");
    const [year, month, day] = datePart.split("-");
    setDtPmnt(day);
  };

  const phone = props.values.contactPhone;
  const onSubmit = async (data) => {
    try {
      if (!selectedDay) {
        setError("selectedDay", {
          type: "manual",
          message: "Insira a nova data de vencimento",
        });
      }
      let CaseNumber;

      const reason = "Alteração de Vencimento";
      const subject = "Alteração de Vencimento";
      const description = `Telefone para contato: ${phone}, Nova data de vencimento: ${selectedDay}, Data atual de vencimento: ${dtPmnt}`;

      const formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      props.values.setLoading(true);
      await FireWebToCase(JSON.parse(formDataJson));

      await delay(10000);

      const response = await GetFireWebToCase(contractId);

      const filteredResponse = response.filter(
        (item) => item.Status === "New" && item.Reason === reason,
      );

      if (filteredResponse.length > 0) {
        const selectedCase = filteredResponse[0];
        CaseNumber = selectedCase.CaseNumber;

        const date = Number(selectedDay);
        await GerarProRataService(contractId, CaseNumber, date);

        props.values.setLoading(false);
        props.proceed();
      }
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  const toggleCalendar = (event) => {
    event.preventDefault();
    setShowCalendar(!showCalendar);
  };

  const handleDayClick = (day) => {
    if (day < 10) {
      setSelectedDay(`0${day}`);
    } else {
      setSelectedDay(day);
    }
    if (selectedDay === currentDay || selectedDay < currentDay) {
      currentMonth = currentMonth + 1;
      if (currentMonth < 10) {
        setSelectedMonth(`0${currentMonth}`);
      } else {
        setSelectedMonth(currentMonth);
      }
    } else {
      if (currentMonth < 10) {
        setSelectedMonth(`0${currentMonth}`);
      } else {
        setSelectedMonth(currentMonth);
      }
    }
    props.handleChangeDatePayment(day);
  };

  const handleClearClick = () => {
    setSelectedDay(null);
    setTimeout(() => {
      setShowCalendar(false);
    }, 600);
  };

  const handleCancelClick = () => {
    setTimeout(() => {
      setShowCalendar(false);
    }, 500);
  };

  const handleOkClick = () => {
    setTimeout(() => {
      setShowCalendar(false);
    }, 500);
  };

  const generateDays = () => {
    let days = [];
    for (let i = 1; i <= 30; i++) {
      days.push(
        <div
          key={i}
          className={`calendar-day ${selectedDay === i ? "selected" : ""}`}
          onClick={() => handleDayClick(i)}
        >
          {i}
        </div>,
      );
    }
    return days;
  };

  const monthNameGenerated = (currentMonth) => {
    let monthName;
    switch (currentMonth) {
      case 1:
        monthName = "janeiro";
        break;
      case 2:
        monthName = "fevereiro";
        break;
      case 3:
        monthName = "março";
        break;
      case 4:
        monthName = "abril";
        break;
      case 5:
        monthName = "maio";
        break;
      case 6:
        monthName = "junho";
        break;
      case 7:
        monthName = "julho";
        break;
      case 8:
        monthName = "agosto";
        break;
      case 9:
        monthName = "setembro";
        break;
      case 10:
        monthName = "outubro";
        break;
      case 11:
        monthName = "novembro";
        break;
      case 12:
        monthName = "dezembro";
        break;
      default:
        monthName = "Número do mês inválido";
        break;
    }

    return monthName;
  };

  const openModal = (event) => {
    event.preventDefault();
    setIsModalAlertOpen(true);
  };

  const closeModal = () => {
    setIsModalAlertOpen(false);
  };

  useEffect(() => {
    if (selectedDay !== null) {
      async function calculateNextPaymentDate() {
        let monthPayment = currentMonth;
        if (currentDay > selectedDay) {
          monthPayment += 1;
        }

        let formattedDate;
        if (selectedDay < 10 && monthPayment < 10) {
          formattedDate = `${selectedDay}/0${monthPayment}/${currentYear}`;
        } else if (selectedDay < 10 && monthPayment >= 10) {
          formattedDate = `${selectedDay}/${monthPayment}/${currentYear}`;
        } else if (selectedDay >= 10 && monthPayment < 10) {
          formattedDate = `${selectedDay}/0${monthPayment}/${currentYear}`;
        } else {
          formattedDate = `${selectedDay}/${monthPayment}/${currentYear}`;
        }

        setNextPaymentDate(formattedDate);
      }

      calculateNextPaymentDate();

      async function calcProRata() {
        props.values.setLoading(true);
        const result = await CalcProRataService(contractId, selectedDay);
        setProRata(result);

        const promotionValue =
          result.valorMensalidade - result.valorVoucher + result.valorAcrescimo;
        setPromotionValue(promotionValue);

        const newValue = result.valorMensalidade + result.valorAcrescimo;
        setNewUnitPrice(newValue);
        props.values.setLoading(false);
      }

      calcProRata();
    }
  }, [selectedDay, currentMonth]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          {selectedDay !== null && (
            <div className="current-payment">
              vencimento atual: <p>todo dia {dtPmnt}</p>
            </div>
          )}
          <label>
            <span className="text-Ivoce">NOVA DATA DE VENCIMENTO</span>
            <div className="day-selector">
              <button
                onClick={(event) => toggleCalendar(event)}
                className="input-day"
              >
                {selectedDay === null ? "--" : selectedDay}
                <img src={arrow_down} />
              </button>

              {showCalendar && (
                <div className="calendar-popup">
                  <div className="calendar">
                    <div className="calendar-days">{generateDays()}</div>
                    <div className="calendar-buttons">
                      <button type="button" onClick={handleClearClick}>
                        Limpar
                      </button>
                      <div>
                        <button type="button" onClick={handleCancelClick}>
                          Cancelar
                        </button>
                        <button type="button" onClick={handleOkClick}>
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="monthly-payment">
                {selectedDay === null ? (
                  <>
                    <p>Valor da mensalidade:</p>

                    <div className="section-value">
                      <p className="value-payment">{formatToBRL(unitPrice)}</p>
                    </div>
                  </>
                ) : (
                  <>
                    {currentDay < selectedDay ? (
                      <>
                        <div className="different-price">
                          <div className="promotion-value">
                            <p>Valor do mês de</p>
                            <p className="monthName">
                              {monthNameGenerated(currentMonth)}:
                            </p>
                          </div>
                          <p className="promotion-value-payment">
                            {formatToBRL(promotionValue)}
                          </p>
                        </div>
                        <div className="alert-mensagem">
                          <img src={alert_icon_yellow} onClick={openModal} />
                          <div className="alert-mensagem-text" onClick={openModal}>
                            <p>
                              Esta fatura terá um ciclo diferente do habitual,
                              então ajustamos o valor. Toque e entenda.
                            </p>
                          </div>
                        </div>
                        <div className="different-price">
                          <div className="promotion-value">
                            <p>Valor dos meses seguintes:</p>
                          </div>
                          <p className="promotion-value-payment">
                            {formatToBRL(newUnitPrice)}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>Valor da mensalidade:</p>
                        <div className="section-value">
                          <p className="value-payment">
                            {formatToBRL(newUnitPrice)}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </label>
          {errors.selectedDay && (
            <span className="loovi-msg-error">
              Insira a nova data de vencimento
            </span>
          )}
        </>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={isCheckboxChecked}
            onChange={(e) => setIsCheckboxChecked(e.target.checked)}
          />
          <p>Estou de acordo com a alteração</p>
        </label>
        {selectedDay !== null && (
          <div className="new-date-payment">
            <p>Próximo vencimento para</p>
            <p className="date-payment">{nextPaymentDate}</p>.
          </div>
        )}
        {isModalAlertOpen && (
          <div className="modal-alert">
            <div className="modal-alert-imgs">
              <div className="alert-title">
                <p>Ajuste de fatura</p>
              </div>
              <div className="alert-text">
                <p>
                  Em razão da alteração da sua data de vencimento, o ciclo da
                  sua fatura atual foi ajustado, resultando em um período mais
                  curto ou mais longo do que o habitual. Para garantir que você
                  não fique sem cobertura e não tenha prejuízos, ajustamos o
                  valor da próxima fatura de forma proporcional. A partir da
                  fatura subsequente, os valores retornarão ao padrão usual.
                </p>
              </div>
              <button onClick={closeModal}>Fechar</button>
            </div>
          </div>
        )}
        <input
          type="submit"
          value="Confirmar alteração"
          disabled={!isCheckboxChecked}
        />
      </form>
    </React.Fragment>
  );
};

export class FormExpirationChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      datePayment: "",
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChangeDatePayment = (value) => {
    this.setState({ datePayment: value });
  };

  render() {
    const { step, datePayment } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      datePayment,
      contactPhone,
      setLoading,
    };

    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleChangeDatePayment={this.handleChangeDatePayment}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  .input-day {
    width: 90%;
    height: 50px;
    border-radius: 10px;
    border: solid 2px #f2f3f8;
    width: 100%;
    padding: 16px 10px;
    color: #a1a4ab;
    font-size: 15px;
    font-weight: 400
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .day-selector {
    position: relative;
  }

  .calendar-popup {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .calendar {
    background-color: #fff;
    max-width: 380px;
    margin: 0px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 12px 18px 0px 18px;
  }

  .calendar-days {
    display: grid;
    grid-template-columns: repeat(
      7,
      1fr
    );
    gap: 10px;
  }

  @media only screen and (max-width: 600px) {
    .calendar-days {
      gap: 0px !important;
    }
  }

  @media screen and (min-width: 768px) {
    .modal-alert-imgs {
      bottom: 24.5% !important
    }
  }

  .calendar-day {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar-day:hover {
    background-color: #5A78FF;
    color: #FFFFFF;
    border-radius: 100%
  }

  .calendar-day.selected {
    background-color: #5A78FF;
    color: #FFFFFF;
    border-radius: 100%
  }

  .calendar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 4px 12px;
    margin-top: 10px
  }

  .calendar-buttons button {
    background-color: transparent;
    border: none;
    color: #5A78FF;
    font-size: 14px;
    font-weight: 500;
  }

  .monthly-payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.5rem;
  }

  .monthly-payment p {
    color: #A1A4AB;
    font-size: 14px;
    font-weight: 400
  }

  .new-date-payment {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2.5rem;
  }

  .new-date-payment p {
    color: #A1A4AB;
    font-size: 14px;
    font-weight: 400
  }

  .date-payment {
    text-decoration: underline;
    margin-left: 0.2rem;
  }

  .section-value {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .value-payment {
    color: #5A78FF !important;
    font-size: 36px !important;
    font-weight: 800 !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
  }

  .checkbox p{
    margin-left: 0.5rem
  }

  .selected-date {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #A1A4AB;
    font-size: 14px;
    font-weight: 400;
  }

  .selected-date p {
    margin-left: 0.5rem;
    text-decoration: underline;
  }

  .current-payment {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #A1A4AB;
    font-size: 14px;
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .current-payment p {
    margin-left: 0.2rem;
    color: #A1A4AB;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
  }

  .alert-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    margin-top: 0.5rem
  }

  .discount-payment, .fees-payment {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .discount-payment {
    color: #5ED7A9 !important;

  }

  .fees-payment {
    color: #FF5A5A !important;
  }

  .promotion-value {
    display: flex;
    flex-direction: row;
  }

  .monthName {
    margin-left: 0.2rem;
    color: #515151 !important;
  }

  .promotion-value-payment {
    color: #5A78FF !important;
    font-weight: 700 !important;
    font-size: 18px !important;
  }

  .different-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .alert-mensagem {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #FFF6DA;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .alert-mensagem img {
    width: 24px;
    height: 24px;
  }

  .alert-mensagem p {
    margin-left: 0.5rem;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
  }

  .alert-mensagem-text {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .modal-alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .modal-alert-imgs {
    background-color: #fff;
    padding: 24px 28px 32px 28px;
    border-radius: 24px 24px 0px 0px;
    width: 100%;
    max-width: 500px;
    height: 290px;
    bottom: 0;
    position: absolute;
    justify-content: space-between;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  .alert-title p {
    font-size: 16px;
    font-weight: 700;
    color: #5A78FF;
  }

  .alert-text p {
    font-size: 14px;
    font-weight: 400;
    color: #4B5563;
  }

  .modal-alert-imgs button {
    color: #999999;
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline
  }
`;
