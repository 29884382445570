import { decode as base64_decode, encode as base64_encode } from "base-64";
import { api, API_BANCO, api_keyed, URLS } from "../helpers/Request";

function normalizePlanItems(planItem) {
  if (planItem.obrigatorio !== undefined) {
    return {
      service_name: planItem.descricao,
      service_code: planItem.codigoItem,
      value: planItem.preco,
      $raw: planItem,
    };
  }

  return {
    service_name: planItem.itemName,
    service_code: planItem.itemCode,
    value: planItem.unitPrice,
    $raw: planItem,
  };
}

export default class SupportService {
  static async getPlans(customerDocument = "", customerContractId = "") {
    try {
      const resultSapAuth = await api
        .post(`${URLS.AUTENTICAR}`, {
          json: { Username: "SevenApp", Password: "Loovi@SevenApp1" },
        })
        .json();

      const resultGetClient = await api_keyed
        .post(`${URLS.URLPROXY}/SAP`, {
          json: base64_encode(
            `{"url": "${URLS.SAP}/GetClient/${customerDocument}/${API_BANCO}", "metodo": "GET", "headers" : { "requester": "SevenApp" }}`,
          ),
        })
        .json();

      const getClientData = JSON.parse(
        base64_decode(resultGetClient),
      ).businessPartner;
      const customerCurrentContract = getClientData.salesContracts.find(
        (s) => s.nCtrId === customerContractId,
      );

      const customerCurrentPlanId = customerCurrentContract.idPlan;
      const customerCurrentSalesContractItems =
        customerCurrentContract.salesContractItems.filter(
          (i) => !!i.recurrence,
        );

      console.log("sevn:", "customerCurrentContract", customerCurrentContract);

      const state = JSON.parse(
        base64_decode(resultGetClient),
      ).businessPartner.bpAddresses.find(
        (a) => a.address === customerContractId,
      ).state;

      const allPlans = await api
        .get(`${URLS.PLANOS}/${state}/app`, {
          headers: { authorization: "Bearer " + resultSapAuth.jwtToken },
        })
        .json();

      const allContractItems = allPlans.find(
        (p) => p.idPlano === customerCurrentPlanId,
      ).itensPlano;

      // Check if reboque (km) is needed in added items
      let itemsAdded = allContractItems
        .filter((i) => !i.obrigatorio)
        .filter(
          (i) =>
            customerCurrentSalesContractItems.findIndex(
              (c) => c.itemCode === i.codigoItem,
            ) > -1,
        )
        .map((i) => normalizePlanItems(i));
      // END: Check if reboque (km) is needed in added items

      // Check if reboque (km) is needed in notAdded items
      let itemsNotAdded = allContractItems
        .filter((i) => !i.obrigatorio)
        .filter(
          (i) =>
            customerCurrentSalesContractItems.findIndex(
              (c) => c.itemCode === i.codigoItem,
            ) === -1,
        )
        .map((i) => normalizePlanItems(i));
      // END: Check if reboque (km) is needed in notAdded items

      // Se o plano atual do contrato vigente tiver reboque contratado
      let hasReboqueContratado = false;
      let itemReboque;
      if (
        customerCurrentSalesContractItems
          .find((i) => i.itemCode === "SRV_ASS24")
          ?.subItems?.find((i) => i.itemCode === "SRV_REBOQ")
      ) {
        hasReboqueContratado = true;
        itemReboque = normalizePlanItems(
          allContractItems
            .find((i) => i.codigoItem === "SRV_ASS24")
            ?.formularioSubItens?.subItemPlano?.find(
              (i) => i.codigoItem === "SRV_REBOQ",
            ),
        );
      }

      // Caso o contrato do usuário tenha no serviço de reboque alguma quilometragem contratada,
      // adiciona o reboque no adquirido, caso contrário, adiciona no a adquirir
      const selectedKmFromContract = customerCurrentSalesContractItems
        .find((i) => i.itemCode === "SRV_ASS24")
        ?.subItems?.find((i) => i.itemCode === "SRV_REBOQ")
        ?.subItemsCategory?.[0];
      if (selectedKmFromContract) {
        itemsAdded.push({
          ...itemReboque,
          $isReboque: true,
          $initialPrice: selectedKmFromContract.price,
          $diferentialPrice: 0,
          $kmOptions: itemReboque.$raw.formularioCategorias.categoriaSubItem,
          value: selectedKmFromContract.price,
          $selectedKmFromContract: selectedKmFromContract,
        });
      } else {
        itemsNotAdded.push({
          ...itemReboque,
          $isReboque: true,
          $kmOptions: itemReboque.$raw.formularioCategorias.categoriaSubItem,
          $initialPrice: 0,
          value: 0,
          $diferentialPrice: 0,
          $selectedKmFromContract: null,
        });
      }

      const finalData = {
        required: customerCurrentSalesContractItems
          .filter((i) => {
            if (!i.recurrence) {
              return false;
            }

            if (
              ![
                "SRV_PT_ROUBO_FURTO",
                "SRV_ROUBO_FURTO",
                "SRV_CARRO_RESERVA",
                "SRV_ASS24",
                "SRV_VIDROS",
                "SRV_SEGUROS_LTI",
              ].includes(i.itemCode)
            ) {
              return false;
            }

            if (
              !allContractItems.find(
                (stateI) => stateI.codigoItem === i.itemCode,
              )?.obrigatorio
            ) {
              return false;
            }

            return true;
          })
          .map((i) => normalizePlanItems(i)),
        added: itemsAdded,
        notAdded: itemsNotAdded,
      };

      return finalData;
    } catch (e) {
      console.error(e);
      window.alert("Erro de servidor ao retornar planos. Tente novamente.");
      return false;
    }
  }
}
