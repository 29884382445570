import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lockr from "lockr";

import { GetFireWebToCase } from "../../services/SalesForceService";
import { PutAuxiliaryDocuments } from "../../services/AuxiliaryDocuments";

// components
import Header from "../Header";
import NavBar from "./NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Feedback from "./Feedback";
import LoaderAnimation from "../LoaderAnimation";
import Sidebar from "./Sidebar";
import { MobileView, BrowserView } from "react-device-detect";
import { useParams } from 'react-router-dom';
import { api_auth, URLS } from "../../helpers/Request";
import iconAlert from "../../assets/icons/icon-exclamation.svg"
import { ModalDocumentation } from "./Consultation/ModalDocumentation";
import { ModalDataCase } from "./Consultation/ModalDataCase";
import {  Comments } from "./Consultation/Comments";

const Content = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isDataModal, setIsDataModal] = useState(false);
  const [isDataDocumentModal, setIsDataDocumentModal] = useState(false);
  const [isDocumentationRefused, setIsDocumentationRefused] = useState(false)
  const [completedStepsOpen, setCompletedStepsOpen] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [currentStatus, setCurrentStatus] = useState({})

  const { caseId } = useParams()
  const clientData = Lockr.get("ClientData");

  const handleDivClick = (index) => {
    setSelectedStepIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCompletedStepsClick = () => {
    setCompletedStepsOpen(!completedStepsOpen);
  };

  const [steps, setSteps] = useState([
    {
      title: "Abertura",
      description: "",
      completed: false,
      status: "atual",
      id:"Aberto"
    },
    {
      title: "Documentação",
      id: "Documentação",
      description: "",
      completed: false,
      status: "futuros",
    },
    {
      title: "Análise",
      id: "Análise",
      description:
        "Nesta etapa, verificamos suas coberturas, o evento relatado e os documentos enviados.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Direcionamento",
      id: "Direcionamento",
      description:
        "Nesta etapa, determinamos a extensão do dano e sugerimos a oficina credenciada mais próxima.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Regulação",
      id: "Regulação",
      description:
        "Nesta etapa, avaliamos os custos de mão de obra e peças para autorizar o conserto do veículo.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Participação Obrigatória",
      id: "Participação Obrigatória",
      description:
        "Nesta etapa, comunicamos o valor do pagamento obrigatório e oferecemos instruções para realizar o pagamento.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Veículo em Reparo",
      id: "Veículo em Reparo",
      description:
        "Estamos monitorando de perto o reparo do seu veículo para garantir que tudo corra conforme o planejado.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Finalização",
      id: "Finalização",
      description: "Make sure to complete step 3",
      completed: false,
      status: "futuros",
    },
  ]);

  const nonCompletedStepsCount = steps.filter((step) => !step.completed).length;

  const completedStepsCount = steps.filter((step) => step.completed).length;

  useEffect(() => {
    (async()=>{
      try {
        setLoading(true)
        const response = await GetFireWebToCase(clientData.customerSelectedCar.contractInfo.nCtrId)

        const findResponse = response.find(
          (item) => item.CaseNumber === caseId
        );

        if(!!findResponse){
          definedStepsStatus(findResponse.Fase__c);
          setCurrentStatus(findResponse)

          if(findResponse.Reason === "Vidros"){
            setSteps([
              {
                title: "Abertura",
                id: "Aberto",
                description: "",
                completed: false,
                status: "atual",
              },
              {
                title: "Documentação",
                id: "Documentação",
                description: "",
                completed: false,
                status: "futuros",
              },
              {
                title: "Análise",
                id: "Análise",
                description:
                  "Nesta etapa, verificamos suas coberturas, o evento relatado e os documentos enviados.",
                completed: false,
                status: "futuros",
              },
              {
                title: "Regulação",
                id: "Regulação",
                description:
                  "Nesta etapa, avaliamos os custos de mão de obra e peças para autorizar o conserto do veículo.",
                completed: false,
                status: "futuros",
              },
              {
                title: "Reparo Autorizado",
                id: "Reparo Autorizado",
                description:
                  "Autorizaremos o reparo do seu veículo nesta etapa. Fique de olho nas atualizações!",
                completed: false,
                status: "futuros",
              },
              {
                title: "Veículo em Reparo",
                id: "Veículo em Reparo",
                description:
                  "Estamos monitorando de perto o reparo do seu veículo para garantir que tudo corra conforme o planejado.",
                completed: false,
                status: "futuros",
              },
              {
                title: "Finalização",
                id: "Finalização",
                description: "Make sure to complete step 3",
                completed: false,
                status: "futuros",
              },
            ])
          }
          
        }else{
          window.location.href = "/sinistros"
        }
        setLoading(false)
      } catch (err) {
        console.log("Erro ao buscar caso!", err)
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    (async()=>{
      const findDocumentationCard = steps[1]

      if(!findDocumentationCard.completed){
        try {
          const resultFotos = await api_auth
          .get(
              `${URLS.FOTOS}/status/${clientData.customerSelectedCar.contractInfo.nCtrId}/${caseId}`
          ).json();

          if(resultFotos.Status === "alguma-reprovada"){
            setIsDocumentationRefused(true)
          }
        } catch (err) {
          console.error("Error ao buscar status de fotos!", err)
        }
      }
    })()
  },[caseId, clientData.customerSelectedCar.contractInfo.nCtrId, steps])

  
  useEffect(()=>{
    const isStustusRegulacao = currentStatus["Fase__c"] === "Regulação"
    const isTotalLoss = (["Qualificado_como_PT__c"] in currentStatus) && currentStatus["Qualificado_como_PT__c"]

    if(isStustusRegulacao && isTotalLoss){
      setSteps([
        {
          id:"Aberto",
          title: "Abertura",
          description: "",
          completed: true,
          status: "concluido",
        },
        {
          title: "Documentação",
          id: "Documentação",
          description: "",
          completed: true,
          status: "concluido",
        },
        {
          title: "Análise",
          id: "Análise",
          description:
            "Nesta etapa, verificamos suas coberturas, o evento relatado e os documentos enviados.",
          completed: true,
          status: "concluido",
        },
        {
          title: "Direcionamento",
          id: "Direcionamento",
          description:
            "Nesta etapa, determinamos a extensão do dano e sugerimos a oficina credenciada mais próxima.",
          completed: true,
          status: "concluido",
        },
        {
          title: "Regulação",
          id: "Regulação",
          description:
            "Nesta etapa, avaliamos os custos de mão de obra e peças para autorizar o conserto do veículo.",
          completed: true,
          status: "concluido",
        },
        {
          title: "Indenização",
          id: "Indenização",
          description:
            "Seu caso foi encaminhado para a equipe de indenização. Fique atento às suas notificações para acompanhar.",
          completed: false,
          status: "atual",
        },
        {
          title: "Finalização",
          id: "Finalização",
          description: "Make sure to complete step 3",
          completed: false,
          status: "futuros",
        },
      ])
    }

  },[currentStatus])

  const definedStepsStatus = (stepCurrent) => {
    let found = false;
    const updatedSteps = steps.map(step => {
      if (step.id === stepCurrent) {
        found = true;
        return { ...step, status: "atual" }
      }

      if (!found) {
        return { ...step, completed: true, tatus: "concluido" };
      }

      return { ...step, completed: false };
    });
    setSteps(updatedSteps);
  };

  const openModalData = () => {
    setIsDataModal(true);
  };

  const closeModalData = () => {
    setIsDataModal(false);
  };

  const openModalDocumentation = () => {
    if(isDocumentationRefused){
      setIsDataDocumentModal(true);
    }
  };

  const closeModalDocumentation = () => {
    setIsDataDocumentModal(false);
  };

  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <LoaderAnimation show={isLoading} />
      {!isDataModal && !isDataDocumentModal ? (
        <>
          <h5 className="loovi-header-title title-color">SINISTRO</h5>
            <Comments chamadoId={currentStatus.Id} />
          <>
            <p className="update-title">Etapas</p>
            <>
              {completedStepsCount > 0 && (
                <>
                  {completedStepsOpen === true ? (
                    <div className="box-completed">
                      <div className="box-steps">
                        {steps
                          .filter((step) => step.completed)
                          .map((step, i) => (
                            <div
                              className="steps-content"
                              key={i}
                              style={{ height: "100%" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {i !== 0 && (
                                  <div
                                    className="step-bar"
                                    style={{
                                      background: step.completed
                                        ? "#5a78ff"
                                        : "#E5E5E5",
                                      marginBottom: "-0.1rem",
                                    }}
                                  ></div>
                                )}
                                <div
                                  className="material-icons"
                                  style={{
                                    color: step.completed
                                      ? "#5a78ff"
                                      : "#E5E5E5",
                                  }}
                                >
                                  check_circle
                                </div>
                                <div
                                  className="step-bar"
                                  style={{
                                    background: step.completed
                                      ? "#5a78ff"
                                      : "#E5E5E5",
                                    marginTop: "-0.1rem",
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="steps-completed-content">
                        <p
                          onClick={handleCompletedStepsClick}
                          className="material-icons"
                          style={{
                            fontSize: "24px",
                            textAlign: "end",
                            color: "#5a78ff",
                            marginTop: "0.2rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          keyboard_arrow_up
                        </p>
                        {steps
                          .filter((step) => step.completed)
                          .map((step, i) => (
                            <div className="steps-content" key={i}>
                              <div
                                className="step-completed"
                                style={{ marginTop: i !== 0 ? "1rem" : "0rem" }}
                              >
                                <p className="step-current-title">
                                  {step.title}
                                </p>
                                {selectedStepIndex === i && (
                                  <p className="step-current-description">
                                    {step.description}
                                  </p>
                                )}
                                {step.title === "Abertura" ? (
                                  <div
                                    className="step-current-info"
                                    onClick={openModalData}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <p>Concluído</p>
                                    <p
                                      className="material-icons"
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "end",
                                        color: "#ffffff",
                                        marginLeft: "0.2rem",
                                      }}
                                    >
                                      arrow_forward
                                    </p>
                                  </div>
                                ) : step.title === "Documentação" ? (
                                  <div
                                    className="step-current-info"
                                    onClick={openModalDocumentation}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <p>Concluído</p>
                                    <p
                                      className="material-icons"
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "end",
                                        color: "#ffffff",
                                        marginLeft: "0.2rem",
                                      }}
                                    >
                                      arrow_forward
                                    </p>
                                    {
                                      isDocumentationRefused && steps[1].completed &&  <img style={{
                                        width:"16px",
                                        height:"16px",
                                        position:"absolute",
                                        top:"13px",
                                        right:"13px"
                                        
                                      }} src={iconAlert} alt="alerta" />
                                    }
                                  </div>
                                ) : (
                                  <div className="step-current-info">
                                    <p>Concluído</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="box-completed"
                      onClick={handleCompletedStepsClick}
                    >
                      <div className="box-steps">
                        <div
                          className="steps-content"
                          style={{ height: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="material-icons"
                              style={{
                                color: "#5a78ff",
                              }}
                            >
                              check_circle
                            </div>
                            <div
                              className="step-bar"
                              style={{
                                background: "#5a78ff",
                                marginTop: "-0.1rem",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="step-completed-content">
                        {
                          isDocumentationRefused && steps[1].completed &&
                          <img src={iconAlert} alt="alerta" style={{marginRight:"8px"}} />
                        }
                        <p className="step-values-completed">

                          {completedStepsCount > 1
                            ? `${completedStepsCount} etapas concluídas`
                            : `${completedStepsCount} etapa concluída`}
                        </p>
                        <p
                          className="material-icons"
                          style={{
                            fontSize: "24px",
                            textAlign: "end",
                            color: "#5a78ff",
                            marginTop: "0.2rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          keyboard_arrow_down
                        </p>
                      </div>
                    </div>
                  )}  
                </>
              )}
              {steps
                .filter((step) => !step.completed)
                .map((step, i) => (
                  <div className="steps-content" key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {completedStepsCount > 0 && (
                        <div
                          className="step-bar"
                          style={{
                            background:
                              step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                            marginBottom: "-0.1rem",
                          }}
                        ></div>
                      )}
                      <div
                        className="material-icons"
                        style={{
                          color:
                            step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                        }}
                      >
                        radio_button_unchecked
                      </div>
                      {i === nonCompletedStepsCount - 1 ? (
                        <></>
                      ) : (
                        <div
                          className="step-bar"
                          style={{
                            background:
                              step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                            marginTop: "-0.1rem",
                          }}
                        ></div>
                      )}
                    </div>
                    {step.status === "atual" ? (
                      <div
                        className="step-current"
                        onClick={() => {
                          if(step.title === "Documentação"){
                            openModalDocumentation()
                          }
                          handleDivClick(i)
                        }}
                        style={{ marginTop: "1rem" }}
                      >
                        <p className="step-current-title">{step.title}</p>
                        {step.description && (
                          <p className="step-current-description">
                            {step.description}
                          </p>
                        )}
                        {
                          step.title === "Documentação" ? 
                          <div className="step-current-info">
                            <p>Em andamento</p>
                            <p
                              className="material-icons"
                              style={{
                                fontSize: "12px",
                                textAlign: "end",
                                color: "#ffffff",
                                marginLeft: "0.2rem",
                              }}
                            >
                              arrow_forward
                            </p>
                          </div>
                          : 
                          <div className="step-current-info">
                            <p>Em andamento</p>
                        </div>
                        }
                      </div>
                    ) : (
                      <div
                        className="step-disabled"
                        style={{ marginTop: "1rem" }}
                      >
                        <p className="step-disabled-title">{step.title}</p>
                      </div>
                    )}
                  </div>
                ))}
            </>
          </>
        </>
      ) : isDataModal && !isDataDocumentModal ? (
        <ModalDataCase closeModalData={closeModalData} chamado={caseId} />
      ) : (
        <ModalDocumentation chamado={caseId} closeModalDocumentation={closeModalDocumentation} nCtrId={clientData.customerSelectedCar.contractInfo.nCtrId} />
      )}
    </React.Fragment>
  );
};

export default Content;

export function ConsultationSinisters (props) {
  const [description, setDescription] = useState()
  const [file, setFilen] = useState()
  const { contactPhone, setLoading } = props;
  const values = {
    description,
    file,
    contactPhone,
    setLoading,
  };


  const handleChange = (event) => {
    setDescription({ description: event.target.value });
  };

  const handleChangeFile = (event) => {
    setFilen({ file: event.target.value });
  };


    return (
      <Section>
        <Header>
          <NavBar backButton blueArrow dashboard={true} />
        </Header>
        <MobileView>
          <Container>
            <Row>
              <Col sm={12} md={{ span: 6, offset: 3 }}>
                <Content
                  values={values}
                  handleChange={handleChange}
                  handleChangeFile={handleChangeFile}
                />
              </Col>
            </Row>
          </Container>
        </MobileView>
        <BrowserView>
          <Container fluid>
            <Row>
              <div className="loovi-fixed-side-column">
                <Sidebar />
              </div>
              <div className="loovi-fluid-column">
                <Col sm={12} md={6}>
                  <div className="loovi-descktop-padding">
                    <Content
                      values={values}
                      handleChange={handleChange}
                      handleChangeFile={handleChangeFile}
                    />
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </BrowserView>
      </Section>
    );
  
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  .title-color {
    color: #a1a4ab !important;
  }

  .update-title {
    color: #515151;
    font-size: 16px;
    font-weight: 700;
    margin-top: 3rem;
  }

  .step-bar {
    height: 100%;
    width: 2px;
  }

  .steps-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .step-current {
    width: 268px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #5a78ff;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    position: relative;
  }

  .step-current-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .step-current-info {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .step-current-info p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }

  .step-disabled {
    width: 268px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #eef2fa;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .step-disabled-title {
    color: #999999;
    font-size: 14px;
    font-weight: 700;
  }

  .step-current-description {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 14.52px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .box-completed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .box-steps {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .steps-completed-content {
    width: 268px;
    background: #eef2fa;
    display: flex;
    flex-direction: column;
    padding: 8px 12px 12px 12px;
    border-radius: 12px;
    border: 1px solid #e0e0ee;
  }

  .step-completed {
    width: 244px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #5a78ff;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    position: relative;
  }

  .step-current-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .step-completed-content {
    width: 268px;
    height: 33px;
    background: #eef2fa;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;

    p{
      margin-bottom: -4px;
    }
  }

  .step-values-completed {
    color: #254dff;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;

    display: flex;
    align-items: center;
    gap: 8px;

  }

  .data-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .data-content-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    column-gap: 10px;
  }

  .data-title {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #515151;
    margin-top: 0.3rem;
  }

  .data-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #a1a4ab;
    margin-top: 0.3rem;
  }

  .btn-download {
    width: 300px;
    min-height: 48px;
    padding: 4px 0px;
    border-radius: 4px;
    color: #5a78ff;
    border: 1px solid #5a78ff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }

  .documentation-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
  }

  .documentation-content-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    color: #515151;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
  }

  .status-documentation {
    width: 85px;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
    text-wrap: nowrap;
  } 
`;
