import React, { useEffect } from 'react';
import useForm from "react-hook-form";
import styled from "styled-components";

export function FirstScreen ({setCurrentStep, status}){
    const { register, handleSubmit, errors, watch} =
    useForm();

    const onSubmit =()=>{
        setCurrentStep("1")
    }

    useEffect(()=>{
        if(status === "alguma-reprovada"){
            setCurrentStep("1")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <Container>
        <p>Você vai precisar:</p>
        <ul>
            <li>Câmera do celular</li>
            <li>Documento do automóvel (CRLV)</li>
            <li>Documento de habilitação (CNH)</li>
            <li>Veículo em local adequado e iluminado</li>
        </ul>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <input 
                    type='checkbox' 
                    name="declaration"
                    ref={register({ required: true })} 
                />
                <p>Declaro, sob as penas da lei, que as informações prestadas são verdadeiras e que as fotos disponibilizadas reproduzem fielmente o estado em que o automóvel se encontra.</p>
            </div>
            {errors.declaration && (
                  <span className="loovi-msg-error">
                    Aceite a declaração para continuar!
                  </span>
                )}
            <input type='submit' placeholder='Continuar' style={ watch('declaration') ? {} : {backgroundColor:  "rgba(213, 213, 213, 1)" , color: "rgba(81, 81, 81, 1)"}} />
        </form>
    </Container>
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 75px;

    & > p{
        color: #c1c1c1;
        font-weight: 400;
        font-size: 15px;
        text-align: center;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 16px;

        margin-top: 30px;
    }

    form > div{
        display: flex;
        align-items: start;
        gap: 6px;
        
        color: rgba(161, 164, 171, 1);
    }
    
    ul{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0;
    }

    li{
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        align-items: center;
        gap: 12px;

        font-size: 14px;
        color: rgba(81, 81, 81, 1);

        &:after{
            content: ' ';

            display: flex;
            align-items: center;
            justify-content: center;

            width: 24px;
            min-width: 24px;
            height: 24px;
            padding-top: 3px;
            background-color: rgba(90, 120, 254, 1);
            border-radius: 50%;

            font-size: 12px;
            color: white;
        }

        
    }

    li:first-child{
        &:after{
            content: '1';
        }
    }

    li:nth-child(2){
        &:after{
            content: '2';
        }
    }

    li:nth-child(3){
        &:after{
            content: '3';
        }
    }

    li:nth-child(4){
        &:after{
            content: '4';
        }
    }
`