import React, { useEffect, useState } from 'react';
import { api_auth, URLS } from '../../../helpers/Request';

export const ModalDocumentation = ({closeModalDocumentation, nCtrId, chamado})=>{
    const [stepsDocumentation, setStepsDocumentation] = useState([
        {
          title: "CNH Aberta",
          status: "Aprovada",
          id:"cnh"
        },
        {
          title: "CRLV do automóvel",
          status: "Aprovada",
          id:"clrv"
        },
        {
          title: "Boletim de  ocorrência",
          status: "Reprovado",
          id:"boletim"
        },
        {
          title: "Dianteira do veículo com lateral do motorista",
          status: "Em análise",
          id:"veiculo-dianteira-esquerda"
        },
        {
          title: "Traseira do veículo com lateral do passageiro",
          status: "Em análise",
          id:"veiculo-traseira-direita"
        },
        {
          title: "Primeira foto do dano ao veículo",
          status: "Em análise",
          id:"dano-primeira-foto"
        },
        {
          title: "Segunda foto do dano ao veículo",
          status: "Em análise",
          id:"dano-segunda-foto"
        },
        {
          title: "Orçamento",
          status: "Em análise",
          id:"orcamento"
        },
    ]);

    useEffect(()=>{
        (async()=>{
            try {
                const resultFotos = await api_auth
                    .get(
                    `${URLS.FOTOS}/${nCtrId}/${chamado}`
                    ).json();

                setStepsDocumentation((prev)=>{
                    return prev.map(element => {
                        const findStep = resultFotos.find(photo=> element.id === photo.TipoFoto.Id )
    
                        if(findStep.Foto && "Aprovacao" in findStep.Foto){
                            if(findStep.Foto.Aprovacao){
                              return {...element, status: "Aprovada"}
                            }else{
                              return {...element, status: "Reprovado"}
                            }
                        }else{
                            return {...element, status: "Em análise"}
                        }
                    });
                })

            } catch (err) {
                console.log("Error ao buscar fotos!", err)
            }
        })()
    },[chamado, nCtrId])

    const handleResetPhoto = async(id, status)=>{
      if(status === "Reprovado"){
        window.location.href = "/sinistros"
        closeModalDocumentation()
      }
    }

    return <>
    <h3
      className="loovi-header-title title-color"
      style={{ textAlign: "center", marginBottom: "3.5rem" }}
    >
      DOCUMENTAÇÃO
    </h3>
    <div className="data-content">
      {stepsDocumentation.map((step, i) => (
        <>
          <div className="documentation-content" key={step.id} onClick={()=> handleResetPhoto(step.id, step.status)}>
            <p className="documentation-content-text">{step.title}</p>
            <div
              className="status-documentation"
              style={{
                color:
                  step.status === "Aprovada"
                    ? "#02AF5C"
                    : step.status === "Reprovado"
                      ? "#ED3D3D"
                      : "#999999",
                background:
                  step.status === "Aprovada"
                    ? "#DAFFED"
                    : step.status === "Reprovado"
                      ? "#FFEDED"
                      : "#EEF2FA",
              }}
            >
              {step.status}
            </div>
          </div>
          {i !== stepsDocumentation.length - 1 && (
            <div
              style={{
                height: "1px",
                background: "#EEF2FA",
                width: "100%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            ></div>
          )}
        </>
      ))}
    </div>
  </>
}